import { useEntries } from "../hooks/useEntries"
import { useParams, useSearchParams, Link } from "react-router-dom"
import EntryDrawer from "../components/EntryDrawer"
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io"
import { formatDistanceToNow } from "date-fns"
import { useState, useEffect } from "react"

function Entries() {
    const { orgId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedEntryId = searchParams.get('entryId')
    const urlTags = searchParams.get('tags')?.split(',').filter(Boolean) || []
    const [tags, setTags] = useState<string[]>(urlTags)
    const { entries, count, statement, isLoading } = useEntries(orgId as string, {
        tags,
    })

    useEffect(() => {
        const params = new URLSearchParams(searchParams)
        if (tags.length > 0) {
            params.set('tags', tags.join(','))
        } else {
            params.delete('tags')
        }
        setSearchParams(params)
    }, [tags, setSearchParams])

    const handleTagClick = (tag: string, e: React.MouseEvent) => {
        e.stopPropagation() // Prevent row click
        if (!tags.includes(tag)) {
            setTags([...tags, tag])
        }
    }

    return <div className="">
        <div className="gap-4 flex p-4 bg-gray-100 items-center">
            <div>Entries</div>

            {tags.length > 0 && (
                <div className="flex gap-2 text-sm items-center">
                    <span className="text-gray-500">Filtered by:</span>
                    {tags.map(tag => (
                        <span
                            key={tag}
                            className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full flex items-center gap-1 cursor-pointer hover:bg-blue-200"
                            onClick={() => setTags(tags.filter(t => t !== tag))}
                        >
                            {tag}
                            <span className="font-bold">×</span>
                        </span>
                    ))}
                </div>
            )}

            <div className="text-gray-500 text-sm">Total: {Number(count).toLocaleString()} entries</div>

            <div className="ml-auto flex items-center gap-2">
                {isLoading ? (
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
                ) : (
                    <div className="relative group">
                        <div className={`px-4 py-1 text-sm rounded ${statement.income > statement.expenses ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'} cursor-pointer`}>
                            Net: ${(statement.income - statement.expenses).toLocaleString()}
                        </div>
                        <div className="absolute text-sm right-0 top-full mt-2 bg-white shadow-lg rounded-lg p-4 hidden group-hover:block w-80 z-10 border">
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="text-gray-600">Income</span>
                                        <span className="text-green-600">${statement.income.toLocaleString()}</span>
                                    </div>
                                    {statement.incomeByCurrency?.map(income => (
                                        <div key={income.currency} className="flex justify-between text-sm pl-4 text-gray-500">
                                            <span>{income.sum?.toLocaleString()} {income.currency}</span>
                                            <span>${income.sumUsd?.toLocaleString()}</span>
                                        </div>
                                    ))}
                                </div>

                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="text-gray-600">Expenses</span>
                                        <span className="text-red-600">${statement.expenses.toLocaleString()}</span>
                                    </div>
                                    {statement.expensesByCurrency?.map(expense => (
                                        <div key={expense.currency} className="flex justify-between text-sm pl-4 text-gray-500">
                                            <span>{expense.sum?.toLocaleString()} {expense.currency}</span>
                                            <span>${expense.sumUsd?.toLocaleString()}</span>
                                        </div>
                                    ))}
                                </div>

                                <div className="border-t pt-2 flex justify-between font-medium">
                                    <span>Net</span>
                                    <span className={statement.income > statement.expenses ? 'text-green-600' : 'text-red-600'}>
                                        ${(statement.income - statement.expenses).toLocaleString()}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>

        {entries?.length === 0 ? (
            isLoading ? (
                <div className="text-center py-12">

                </div>
            ) : (
                <div className="text-center py-12">
                    <h3 className="text-lg font-medium text-gray-900 mb-2">No entries yet</h3>
                    <p className="text-gray-500 mb-4">Get started by creating your first entry</p>
                    <Link
                        to={`/o/${orgId}/docs`}
                        className="text-blue-600 hover:text-blue-800 font-medium"
                    >
                        View documentation →
                    </Link>
                </div>
            )
        ) : (
            <table className="min-w-full">
                <thead className="sticky top-0 bg-white border-b border-gray-200 border-b-1">
                    <tr className="border-b text-sm">
                        <th className="text-left p-2 pl-4">Date</th>
                        <th className="text-left p-2">Type</th>
                        <th className="text-left p-2">Amount</th>
                        <th className="text-left p-2">Value in USD</th>
                        <th className="text-left p-2">Tags</th>
                    </tr>
                </thead>
                <tbody className="text-sm">
                    {isLoading ? (
                        <tr>
                            <td colSpan={5} className="text-center p-2">Loading...</td>
                        </tr>
                    ) : (
                        entries?.map(entry => (
                            <tr
                                key={entry.id}
                                className="border-b cursor-pointer hover:bg-gray-50"
                                onClick={() => setSearchParams({ entryId: entry.id })}
                            >
                                <td className="pl-4 p-2">{formatDistanceToNow(new Date(entry.dateOfEntry))} ago</td>
                                <td className="p-2">
                                    {entry.type === 'income' ? (
                                        <span className="text-green-600 flex items-center gap-1">
                                            <IoMdArrowDropup className="text-lg" />
                                            Income
                                        </span>
                                    ) : (
                                        <span className="text-red-600 flex items-center gap-1">
                                            <IoMdArrowDropdown className="text-lg" />
                                            Expense
                                        </span>
                                    )}
                                </td>
                                <td className={`p-2`}>
                                    {entry.amount} {entry.currency}
                                </td>
                                <td className="p-2">
                                    {entry.valueAtDate?.toLocaleString()} USD
                                </td>
                                <td className="p-2">
                                    {entry.tags.map((tag, i) => (
                                        <span
                                            key={i}
                                            onClick={(e) => handleTagClick(tag, e)}
                                            className="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-semibold text-gray-700 mr-1 hover:bg-gray-300 cursor-pointer"
                                        >
                                            {tag}
                                        </span>
                                    ))}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        )}

        {selectedEntryId && <EntryDrawer
            orgId={orgId as string}
            entryId={selectedEntryId}
        />}
    </div>
}

export default Entries