import { logout } from "../api/auth";
import { useUser } from "../context/UserManager";
import { Link, useParams, useLocation } from "react-router-dom";
import { FaHome, FaList, FaKey, FaBook, FaSignOutAlt, FaUser, FaChevronDown, FaUsers } from "react-icons/fa";
import { useState, useRef, useEffect } from "react";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

function NavBar() {
    const { user } = useUser();
    const { orgId } = useParams();
    const location = useLocation();

    const logoutHandler = async () => {
        if (!window.confirm("Are you sure you want to logout?")) return
        await logout();
    }

    const getLinkClass = (path: string) => {
        const isActive = location.pathname === path;
        return `relative transition-all duration-200 py-3 flex items-center hover:text-gray-900 group ${isActive ? 'text-black' : 'text-gray-600'
            }`;
    }

    const selectedOrg = user?.organizations.find((org) => org.id === orgId);

    return (
        <div className="backdrop-blur-sm bg-white sticky top-0 z-50 border-b border-gray-200 w-screen">
            <div className="w-full">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row gap-4 items-center">
                        <div className="text-gray-600 pl-4">Firebook</div>

                        <DropdownMenu.Root>
                            <DropdownMenu.Trigger className="flex items-center gap-2 px-4 py-3 hover:bg-gray-100 transition-colors duration-200 cursor-pointer">
                                <span className="text-gray-600">{selectedOrg?.name}</span>
                                <FaChevronDown className="text-gray-600 text-xs" />
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Portal>
                                <DropdownMenu.Content className="bg-white border border-gray-200 rounded-lg shadow-lg w-48 mt-1">
                                    {user?.organizations?.map((org) => (
                                        <DropdownMenu.Item key={org.id} className="outline-none">
                                            <Link
                                                to={`/o/${org.id}`}
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            >
                                                {org.name}
                                            </Link>
                                        </DropdownMenu.Item>
                                    ))}
                                </DropdownMenu.Content>
                            </DropdownMenu.Portal>
                        </DropdownMenu.Root>

                        <div className="flex gap-6">
                            <Link
                                to={`/o/${orgId}`}
                                className={getLinkClass(`/o/${orgId}`)}
                            >
                                <FaHome className="mr-2" />
                                <span>Overview</span>
                                <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-black transform origin-left transition-transform duration-200 ${location.pathname === `/o/${orgId}` ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                                    }`} />
                            </Link>

                            <Link
                                to={`/o/${orgId}/entries`}
                                className={getLinkClass(`/o/${orgId}/entries`)}
                            >
                                <FaList className="mr-2" />
                                <span>Entry feed</span>
                                <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-black transform origin-left transition-transform duration-200 ${location.pathname === `/o/${orgId}/entries` ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                                    }`} />
                            </Link>

                            <Link
                                to={`/o/${orgId}/api-keys`}
                                className={getLinkClass(`/o/${orgId}/api-keys`)}
                            >
                                <FaKey className="mr-2" />
                                <span>API Keys</span>
                                <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-black transform origin-left transition-transform duration-200 ${location.pathname === `/o/${orgId}/api-keys` ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                                    }`} />
                            </Link>

                            <Link
                                to={`/o/${orgId}/docs`}
                                className={getLinkClass(`/o/${orgId}/docs`)}
                            >
                                <FaBook className="mr-2" />
                                <span>Docs</span>
                                <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-black transform origin-left transition-transform duration-200 ${location.pathname === `/o/${orgId}/docs` ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                                    }`} />
                            </Link>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <DropdownMenu.Root>
                            <DropdownMenu.Trigger className="flex items-center h-full px-3 hover:bg-gray-100 transition-colors duration-200 cursor-pointer">
                                <div className="w-7 h-7 bg-gray-100 rounded-full flex items-center justify-center text-gray-900 mr-2">
                                    <FaUser className="w-3 h-3" />
                                </div>
                                <span className="text-gray-900">{user?.firstName} {user?.lastName}</span>
                                <FaChevronDown className="ml-2 text-gray-600" />
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Portal>
                                <DropdownMenu.Content className="bg-white rounded-lg shadow-lg py-1 z-10 border border-gray-200 w-48 mt-2">
                                    <DropdownMenu.Item className="outline-none">
                                        <Link
                                            to={`/o/${orgId}/settings`}
                                            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer flex items-center group"
                                        >
                                            <FaUsers className="mr-2 text-gray-400 group-hover:text-black transition-colors duration-200" />
                                            <span className="group-hover:text-black transition-colors duration-200">Team Settings</span>
                                        </Link>
                                    </DropdownMenu.Item>
                                    <DropdownMenu.Item className="outline-none">
                                        <div
                                            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer flex items-center group"
                                            onClick={logoutHandler}
                                        >
                                            <FaSignOutAlt className="mr-2 text-gray-400 group-hover:text-black transition-colors duration-200" />
                                            <span className="group-hover:text-black transition-colors duration-200">Logout</span>
                                        </div>
                                    </DropdownMenu.Item>
                                </DropdownMenu.Content>
                            </DropdownMenu.Portal>
                        </DropdownMenu.Root>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;