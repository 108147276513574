import NavBar from "./components/NavBar";
import { Navigate, Route, Routes } from "react-router-dom";
import ApiKeysPage from "./containers/ApiKeys";
import Docs from "./containers/Docs";
import Entries from "./containers/Entries";
import Home from "./containers/Home";
import Settings from "./containers/Settings";
function Authenticated() {
    return <div className="flex flex-1 flex-col">
        <NavBar />
        <Routes>
            <Route path="/api-keys" element={<ApiKeysPage />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/entries" element={<Entries />} />
            <Route path="/" element={<Home />} />
            <Route path="/settings" element={<Settings />} />
        </Routes>
    </div>
}

export default Authenticated;