import useSWR, { mutate } from "swr";
import { getFetcher, post, del } from "../api/api";
import { ApiOrgMember } from "../api.types";

export function useTeamMembers(orgId: string) {
    const { data, error } = useSWR<ApiOrgMember[]>(
        `/organizations/${orgId}/members`,
        getFetcher
    );

    return {
        teamMembers: data,
        isLoading: !data && !error,
        error
    };
}

export function useAddTeamMember(orgId: string) {
    // post to /organizations/{orgId}/members
    return async (email: string) => {
        const res = await post(`/organizations/${orgId}/members`, { email });

        if (res.message) {
            throw new Error(res.message);
        }

        await mutate(`/organizations/${orgId}/members`, null, true);
    }
}

export function useRemoveTeamMember(orgId: string) {
    return async (memberId: string) => {
        const res = await del(`/organizations/${orgId}/members/${memberId}`);

        if (res.message) {
            throw new Error(res.message);
        }

        await mutate(`/organizations/${orgId}/members`, null, true);
    }
}
