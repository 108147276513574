import { useParams } from "react-router-dom";
import { useAddTeamMember, useRemoveTeamMember, useTeamMembers } from "../hooks/useTeamMembers";
import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';

function Settings() {
    const { orgId } = useParams();
    const { teamMembers, isLoading, error } = useTeamMembers(orgId as string);
    const addTeamMember = useAddTeamMember(orgId as string);
    const removeTeamMember = useRemoveTeamMember(orgId as string);
    const [email, setEmail] = useState('');

    const handleAddMember = async () => {
        try {
            await addTeamMember(email);
            setEmail('');
        } catch (error) {
            alert(`Failed to add team member: ${error instanceof Error ? error.message : 'Unknown error'}`);
        }
    };

    const handleRemoveMember = async (memberId: string) => {
        try {
            await removeTeamMember(memberId);
        } catch (error) {
            alert(`Failed to remove team member: ${error instanceof Error ? error.message : 'Unknown error'}`);
        }
    };

    return <div className="mx-auto container">
        <div className="text-2xl font-bold mt-6">
            Settings
        </div>

        <div className="mt-4">
            <Dialog.Root>
                <Dialog.Trigger asChild>
                    <button className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                        Add Team Member
                    </button>
                </Dialog.Trigger>
                <Dialog.Portal>
                    <Dialog.Overlay className="fixed inset-0 bg-black/50" />
                    <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg">
                        <Dialog.Title className="text-xl font-bold mb-4">Add Team Member</Dialog.Title>
                        <div className="mb-4">
                            <label className="block mb-2">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-3 py-2 border rounded"
                                placeholder="Enter email address"
                            />
                        </div>
                        <div className="flex justify-end gap-2">
                            <Dialog.Close asChild>
                                <button className="px-4 py-2 text-gray-600 hover:text-gray-800">
                                    Cancel
                                </button>
                            </Dialog.Close>
                            <Dialog.Close asChild>
                                <button
                                    onClick={handleAddMember}
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Add Member
                                </button>
                            </Dialog.Close>
                        </div>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>

            {isLoading ? (
                <div>Loading team members...</div>
            ) : error ? (
                <div>Error loading team members</div>
            ) : (
                <table className="w-full">
                    <thead>
                        <tr className="text-left border-b">
                            <th className="py-2">Name</th>
                            <th className="py-2">Email</th>
                            <th className="py-2">Role</th>
                            <th className="py-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teamMembers?.map(member => (
                            <tr key={member.id} className="border-b">
                                <td className="py-2">{member.user.firstName} {member.user.lastName}</td>
                                <td className="py-2">{member.user.email}</td>
                                <td className="py-2">{member.role}</td>
                                <td className="py-2">
                                    <button
                                        onClick={() => handleRemoveMember(member.id)}
                                        className="px-3 py-1 text-sm bg-red-500 text-white rounded hover:bg-red-600"
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    </div>
}

export default Settings;